import React, {useCallback, useState} from 'react';
import {Control, RegisterOptions, useController} from 'react-hook-form';

import cn from 'classnames';

import Input, {InputProps} from './Input';

import {getInputState} from '../../../utils/inputHelper';

import Correct from '../../Icons/Correct';
import Wrong from '../../Icons/Wrong';
import Email from '../../Icons/Email';

import styles from './Input.module.scss';

export interface EmailInputProps extends Omit<InputProps, 'type' >{
    control: Control<any>;
}

const EmailInput: React.FC<EmailInputProps> = ({control, ...rest}) => {
    const {fieldState} = useController({
        name: 'email',
        control,
    });
    const [isFocused, setIsFocused] = useState(false);
    const state = getInputState(fieldState, isFocused);

    const iconsMap = {
        valid: <Correct/>,
        error: <Wrong/>,
        active: <Email color="#000000"/>,
        default: <Email/>,
    };

    const classMap = {
        valid: styles.input_valid,
        error: styles.input_error,
        active: styles.input_active,
        default: styles.input_default,
    };

    const EMAIL_PATTERN: RegExp = /^[-A-Za-z0-9!#$%&'*+\/=?^_`{|}~]+(?:\.[-A-Za-z0-9!#$%&'*+\/=?^_`{|}~]+)*@(?:[A-Za-z0-9]([-A-Za-z0-9]{0,61}[A-Za-z0-9])?\.)+(?:[A-Za-z]+)$/i; // eslint-disable-line

    const constraints: RegisterOptions = !rest.disabled
        ? {
            pattern: {value: EMAIL_PATTERN, message: 'Некорректный Email'},
            required: 'Не указан Email',
        }
        : {};

    const onInputFocus = useCallback(() => {
        setIsFocused(true);
    }, []);

    const onInputBlur = useCallback(() => {
        setIsFocused(false);
    }, []);

    return (
        <div className={styles.input_container}>
            <div className={styles.icon}>
                {iconsMap[state]}
            </div>
            <Input
                {...rest}
                type="email"
                id="email"
                name="email"
                placeholder="Почта"
                rules={constraints}
                className={cn(styles.input, classMap[state])}
                onFocus={onInputFocus}
                onBlur={onInputBlur}
            />
        </div>
    );
};

export default React.memo(EmailInput);