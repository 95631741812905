/* eslint-disable camelcase */
import React from 'react';
import {Control, FieldErrors, UseFormGetValues, UseFormRegister, UseFormSetValue} from 'react-hook-form';

import {useSelector} from 'react-redux';

import {Panel} from 'rsuite';

import {Link} from 'react-router-dom';

import IMonitorOutput from '../../../models/Monitor/IMonitor';

import {userSelector} from '../../../redux/authSlice';

import FormGroupWithError from '../../UI/FormGroup/FormGroupWithError';

import Checkbox from '../../UI/Checkbox/Checkbox';

import InputNumber from '../../UI/Input/InputNumber';

import {AppUrls} from '../../../constants/urls';

import {telegramChatAPI} from '../../../api/telegramChatAPI';

import ExtraSettingsSubform from './ExtraSettingsSubform';

import styles from './MonitorForm.module.scss';

interface IMonitorCommonSettingsSubformProps {
    item?: IMonitorOutput,
    getValues: UseFormGetValues<any>,
    errors: FieldErrors<any>,
    setValue: UseFormSetValue<any>,
    control: Control<any>,
    register: UseFormRegister<any>,
}

const MonitorCommonSettingsSubform: React.FC<IMonitorCommonSettingsSubformProps> = ({
    item,
    getValues,
    errors,
    setValue,
    control,
    register,
}) => {
    const user = useSelector(userSelector);
    const {data: chats, isLoading} = telegramChatAPI.useGetActiveTelegramChatsQuery();


    return (
        <>
            <FormGroupWithError
                error={errors?.check_interval?.message}
                label="Интервал проверки, мин"
            >
                <InputNumber
                    name="check_interval"
                    setValue={setValue}
                    control={control}
                    min={user?.tariff?.monitor_min_check_interval_limit ?? 5}
                    defaultValue={item?.check_interval ?? 5}
                    required
                />
            </FormGroupWithError>

            <div className="form-group d-flex align-items-center">
                <Checkbox
                    disabled={!user?.email_confirmed}
                    {...register('notify_by_email')}
                >
                    Уведомлять на подтвержденный Email
                </Checkbox>
                {
                    !user?.email_confirmed
                    && <div className={styles.error}>Необходимо подтвердить Email</div>
                }
            </div>

            <div className="form-group d-flex align-items-center">
                Уведомления через Telegram
                {
                    !isLoading && chats?.length === 0
                    && <div className={styles.error}>
                        Необходимо добавить один или несколько чатов 
                        в <Link to={AppUrls.SETTINGS}>Настройках</Link> и активировать
                    </div>
                }
            </div>

            {
                !isLoading
                && chats
                && chats.length > 0
                && chats.map(chat => (
                    <div key={chat.id} className="form-group d-flex align-items-center">
                        <Checkbox
                            value={`${chat.id}`}
                            {...register('telegram_chats')}
                        >
                            {chat.description ?? chat.chat}
                        </Checkbox>
                    </div>
                ))
            }

            <Panel header="Расширенные настройки" collapsible bordered className={styles.panel}>
                <ExtraSettingsSubform
                    item={item}
                    getValues={getValues}
                    errors={errors}
                    control={control}
                    register={register}
                />
            </Panel>
        </>);
};

export default MonitorCommonSettingsSubform;