import React, {useCallback, useState} from 'react';

import {useSelector} from 'react-redux';

import {useSearchParams} from 'react-router-dom';

import {userSelector} from '../../redux/authSlice';
import RefreshButton from '../UI/IconButton/RefreshButton';
import UserRow from './UserRow';
import {Pages} from '../../constants/pages';
import LoaderCentered from '../UI/Loader/LoaderCentered';
import Table from '../UI/Table/Table';
import {userAPI} from '../../api/userAPI';
import {getPollingInterval} from '../../utils/pollingHelper';
import TableBox from '../UI/Box/TableBox/TableBox';

import Search from '../UI/Search/Search';

import IOrderBy from '../../models/IOrderBy';

import styles from './Users.module.scss';

const Users: React.FC = () => {
    const user = useSelector(userSelector);
    const [searchParams, setSearchParams] = useSearchParams();
    const page = Number(searchParams.get('page') ?? 1);

    const [sort, setSort] = useState<IOrderBy>();
    const [filters, setFilters] = useState<string[]>([]);

    const {data: pagination, isLoading, refetch, isFetching} = userAPI.useGetUsersQuery({
        page,
        sort,
        filters,
    }, {
        pollingInterval: getPollingInterval(),
    });
    const onRefreshButtonClick = useCallback(() => {
        refetch();
    }, []);

    const onSearchSubmit = useCallback((search: string) => {
        searchParams.set('page', '1');
        setSearchParams(searchParams);
        setFilters([`email=${search}`]);
    }, []);


    return (
        <TableBox>
            <LoaderCentered active={isFetching}/>
            <div className={styles.head}>
                <h3>{Pages.USERS}</h3>
                <RefreshButton
                    title="Обновить"
                    onClick={onRefreshButtonClick}
                />
            </div>

            <Search  onSubmit={onSearchSubmit} placeholder="Email"/>

            <Table
                columns={[
                    {label: 'ID', sort: 'id'},
                    {label: 'Дата регистрации', sort: 'created_at'},
                    {label: 'Последняя активность', sort: 'last_activity_at'},
                    {label: 'Имя', sort: 'first_name'},
                    {label: 'Фамилия', sort: 'last_name'},
                    {label: 'Email', sort: 'email'},
                    {label: 'Пояс', sort: 'timezone'},
                    {label: 'Тариф', sort: 'tariff.name'},
                    {label: 'Активен до', sort: 'active_to'},
                    {label: '∞', sort: 'is_recurring'},
                    {label: '👁/⚠️'},
                    {label: ''},
                ]}
                items={pagination?.items}
                rowFactory={item => <UserRow
                    key={item.id}
                    item={item}
                    tzString={user?.timezone}
                />}
                isLoading={isLoading}
                pagination={pagination}
                sort={sort}
                setSort={setSort}
            />
        </TableBox>
    );
};

export default Users;