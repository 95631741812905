import React, {MouseEvent, useCallback} from 'react';

import {toast} from 'react-toastify';

import {useDispatch} from 'react-redux';

import ITelegramChat from '../../models/ITelegramChat';
import TrashButton from '../UI/IconButton/TrashButton';
import {telegramChatAPI} from '../../api/telegramChatAPI';
import {TypedDispatch} from '../../types';
import {fetchMe} from '../../redux/authSlice';

import StatusToggle from '../UI/StatusToggler/StatusToggle';

import styles from './TelegramChats.module.scss';

interface ITelegramChatRowProps {
    item: ITelegramChat,
}

const TelegramChatRow: React.FC<ITelegramChatRowProps> = ({item}) => {
    const dispatch = useDispatch<TypedDispatch>();
    const [deleteTelegramChat] = telegramChatAPI.useDeleteTelegramChatMutation();
    const [updateTelegramChat] = telegramChatAPI.useUpdateTelegramChatMutation();

    const onClickToggleStatus = useCallback(async(event: MouseEvent) => {
        event.stopPropagation();
        try {
            await updateTelegramChat({
                id: item.id,
                active: !item.active,
            }).unwrap();
            toast.info(item.active ? 'Чат деактивирован' : 'Чат активирован');
        }catch (e) {}
    }, [item.active]);

    const onClickDelete = useCallback(async(event: MouseEvent) => {
        event.stopPropagation();
        try {
            await deleteTelegramChat(item.id).unwrap();
            dispatch(fetchMe());
            toast.info('Чат удален');
        }catch (e) {}
    }, []);

    return (
        <tr className={styles.row}>
            <td>{item.chat}</td>
            <td>{item.description}</td>
            <td><StatusToggle condition={item.active} onClick={onClickToggleStatus} /></td>
            <td><TrashButton onClick={onClickDelete} title="Удалить"/></td>
        </tr>
    );
};

export default TelegramChatRow;