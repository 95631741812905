import React, {ReactNode} from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {faEllipsis} from '@fortawesome/free-solid-svg-icons';

import MoreActions from './MoreActions';

import styles from './MoreActions.module.scss';


interface IMoreActionsDotsProps {
    children: ReactNode | null |undefined,
}

const MoreActionsDots: React.FC<IMoreActionsDotsProps> = ({children}) => { 

    const anchor = <div className={styles['more']}>
        <FontAwesomeIcon icon={faEllipsis}/>
    </div>;

    return (
        <MoreActions anchor={anchor}>
            {children}
        </MoreActions>
    );
};

export default React.memo(MoreActionsDots);