import React from 'react';

import IUserOutput from '../../models/IUser';
import {Pages} from '../../constants/pages';
import Box from '../UI/Box/Box';
import ProfileForm from './ProfileForm';
import PasswordReset from './PasswordReset';

import styles from './Profile.module.scss';

interface IProfileProps {
    user: IUserOutput,
}

const Profile: React.FC<IProfileProps> = ({user}) => {
    return (
        <>
            <h3 className={styles.title}>{Pages.SETTINGS}</h3>
            <div className={styles.container}>
                <div className={styles.profile_form}>
                    <Box>
                        <ProfileForm user={user}/>
                    </Box>
                </div>
                <div>
                    <Box>
                        <PasswordReset/>
                    </Box>
                </div>
            </div>
        </>
    );
};

export default Profile;